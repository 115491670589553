import React from 'react';
import useThemeOptions from '@flogy/gatsby-theme-fgs-legal/src/hooks/useThemeOptions';
import SEO from '../../../../landing/components/seo';

const IntroText = () => {
  const themeOptions = useThemeOptions();

  return (
    <>
      <SEO title="Data Privacy" />
      <h1>
        Data Privacy statement of {themeOptions.companyAccusative}, {themeOptions.companyLocation}
      </h1>
      <p>Last changed: {themeOptions.dataPrivacy.lastChangedDate}</p>
      <p>
        {themeOptions.companyNominative} takes the privacy of your data very seriously. Your personal data is treated
        trustworthy and aligned with the law and accordingly to this data privacy statement.
      </p>
      <p>
        By visiting this website you are giving consent to the collection and usage of information as declared in this
        data privacy statement. You also confirm to understand that this data privacy statement can be changed at any
        time and without prior announcement.
      </p>
      <p>
        {themeOptions.companyNominative} points out that there can be security vulnerabilities when transferring data
        over the internet (e.g. when communicating via email). A flawless protection of the data against the access by
        third parties is not entirely possible.
      </p>
    </>
  );
};

export default IntroText;
