import React from 'react';
import { Link } from '@flogy/gatsby-theme-fgs-layout';

const customFeatures = (companyAccusative, companyDative) => [
  {
    key: 'adminPortal',
    title: 'Admin portal',
    body: (
      <>
        <p>
          To run {companyAccusative} we use Amazon AWS. This means that all data entered by you or your guests are
          stored and processed on the servers of Amazon (
          <Link href="https://aws.amazon.com/privacy/">Privacy Notice of Amazon AWS</Link>).
        </p>
      </>
    ),
  },
];

export default customFeatures;
