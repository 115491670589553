import React from 'react';
import { Link } from '@flogy/gatsby-theme-fgs-layout';

const predefinedFeatures = (companyAccusative, companyDative) => [
  {
    key: 'cookies',
    title: 'Cookies',
    body: (
      <>
        <p>
          The web sites partly use so called cookies. Cookies do not harm your device or contain any viruses. They are
          used to make this website more user-friendly and more effective. Basically, cookies are small text files that
          are saved to your device for this purpose.
        </p>

        <p>
          You can configure your browser in a way that you are able to decide whether to allow individual cookies or
          not, or to generally disallow saving cookies. However, by disallowing cookies the functionality of this
          website might be limited.
        </p>
      </>
    ),
  },
  {
    key: 'serverLogFiles',
    title: 'Server log files',
    body: (
      <>
        <p>
          The provider of this website collects and stores information that is automatically submitted by your browser
          in so called server log files. Those are:
        </p>
        <ul className="list">
          <li>Browser type and version</li>
          <li>Used operation system</li>
          <li>Referrer URL</li>
          <li>Hostname of the accessing device</li>
          <li>Timestamp of the server request</li>
        </ul>
        <p>
          This information is not relatable to specific people. Also, the data is not merged together with information
          from other data sources. However, the data can be subsequently checked for specific evidence in cases of any
          illegal activity.
        </p>
      </>
    ),
  },
  {
    key: 'googleAnalytics',
    title: 'Google Analytics',
    body: (
      <>
        <p>
          To continuously improve this service, Google Analytics is used as a web analysis tool. It provides statistics
          and graphics that help {companyDative} to understand how this website is used. The data about the usage of
          this website are stored on the international servers of Google Analytics with a shortened IP address. Like
          that it is not possible to identify individual visitor devices. Google Analytics acts regarding to the
          Swiss-U.S. Privacy Shield contracts and is registered at the US ministry for the Swiss-U.S. Privacy Shield
          (find more information about the Swiss-U.S. Privacy Shield here:{' '}
          <Link href="https://www.privacyshield.gov/Swiss-US-Privacy-Shield-FAQs">
            https://www.privacyshield.gov/Swiss-US-Privacy-Shield-FAQs
          </Link>
          ). The transmitted IP address is not merged with any other data from Google Analytics. A transmission of those
          data to third parties is only done if required by legal instructions or to process those data.
        </p>
        <p>
          You can prevent the collection and procession of data related to your usage of this website by Google by
          downloading and installing the browser plugin that is accessible here:{' '}
          <Link href="http://tools.google.com/dlpage/gaoptout?hl=en">
            http://tools.google.com/dlpage/gaoptout?hl=en
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    key: 'ssl',
    title: 'SSL encryption',
    body: (
      <>
        <p>
          This site is using an SSL encryption to secure and protect confidentional data like for example requests you
          are sending to {companyAccusative}. You can see that the connection is encrypted because the URL in your
          address field of the browser is changing from "http://" to "https://" and because a little lock symbol shows
          up next to it.
        </p>

        <p>
          No data you are transferring to the servers of this website can be read by third parties if SSL encryption is
          activated.
        </p>
      </>
    ),
  },
];

export default predefinedFeatures;
