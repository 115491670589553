import React from 'react';
import { Link } from '@flogy/gatsby-theme-fgs-layout';
import useThemeOptions from '@flogy/gatsby-theme-fgs-legal/src/hooks/useThemeOptions';

const OutroText = () => {
  const {
    dataPrivacy: {
      responsiblePerson: { nameAccusative, email },
    },
  } = useThemeOptions();

  return (
    <>
      <h2>Your data rights</h2>
      <p>
        At any time you have the right to request a free report about how yuor personal data is stored, where it comes
        from, where it is sent to, as well as how it is used. You are also allowed to request to change, disable or
        delete your data. If you have questions about this or generally about personal data you can always get in
        contact with {nameAccusative}, <Link href={`mailto:${email}`}>{email}</Link>.
      </p>
    </>
  );
};

export default OutroText;
